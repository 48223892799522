import React from "react";
import xiaolwl from "./xiaolwl.png";

function Header() {
  return (
    <div className="my-2">
      <img alt="header" src={xiaolwl} />
    </div>
  );
}

export default Header;
